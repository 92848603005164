import React, { ReactNode } from "react";
import classNames from "classnames";

interface IHeadline {
  variant: "h1" | "h2" | "h3" | "h4" | "h5";
  text?: string;
  className?: string;
  children?: ReactNode;
  color?: string;
}

export function Headline({
  variant = "h2",
  text,
  className = "",
  children,
  color = "text-gray-900",
}: IHeadline): JSX.Element {
  if (!text && !children) {
    return <></>;
  }
  const id = text?.trim().toLowerCase().replace(/\s/g, "");
  switch (variant) {
    case "h1":
      return (
        <h1
          className={classNames({
            [`${color}`]: !Boolean(className),
            [className]: Boolean(className),
          })}
          id={id}
        >
          {text && text}
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2
          className={classNames({
            [`${color}`]: !Boolean(className),
            [className]: Boolean(className),
          })}
          id={id}
        >
          {text && text}
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3
          className={classNames({
            [`${color}`]: !Boolean(className),
            [className]: Boolean(className),
          })}
          id={id}
        >
          {text && text}
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={`${color} ${className}`} id={id}>
          {text && text}
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 className={`text-xs font-bold ${color} ${className}`} id={id}>
          {text && text}
          {children}
        </h5>
      );
  }
}
