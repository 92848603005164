import React, { Fragment } from "react";
import Image from "next/image";
import {
  ComponentElementsResponsiveImage,
  Enum_Componentnavigationlinkbutton_Buttonstyle,
  Enum_Componentnavigationlinkbutton_Target,
  Enum_Componentsectionshero_Variant,
  HeroSectionsFragment,
} from "@/graphql/generated/graphql";
import { Container } from "@/components/Container";
import { Paragraph } from "@/components/Paragraph";
import { getResponsiveImageClasses } from "@/lib/getResponsiveImageClasses";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton";
import classNames from "classnames";

export function Hero({
  button,
  responsiveImage,
  subTitle,
  descriptionHtml,
  titleHtml,
  variant,
}: HeroSectionsFragment): JSX.Element {
  const responsiveImageClasses = getResponsiveImageClasses(
    (responsiveImage?.filter(Boolean) as ComponentElementsResponsiveImage[]) ||
      []
  );
  const hasHeroImage = responsiveImage && responsiveImage.length > 0;

  return (
    <div
      className={classNames(`relative`, {
        [`componentHeroSmall`]:
          variant === Enum_Componentsectionshero_Variant.Small,
      })}
    >
      <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
        {responsiveImage?.map((image) => (
          <Fragment key={image?.id}>
            {image?.image?.data?.attributes?.url && (
              <Image
                src={image?.image.data?.attributes?.url || ""}
                alt={image?.image.data?.attributes?.alternativeText || ""}
                fill
                sizes="100vw"
                className={`${
                  responsiveImageClasses[image?.screenSize || ""]
                } w-full h-full object-center object-cover`}
                priority={true}
                loading="eager"
              />
            )}
          </Fragment>
        ))}
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 bg-gray-900 opacity-0"
      />
      <Container className="relative">
        <div
          className={`max-w-[1200px]
          ${hasHeroImage ? "py-24" : "py-12"}
          `}
        >
          <div
            className={`${
              hasHeroImage
                ? "inline-block bg-black p-8 bg-opacity-70 rounded-lg text-white "
                : ""
            }`}
          >
            <h1
              className={classNames(
                `inline-block font-argentum font-black sm:leading-[1.2] tracking-wider
            ${!hasHeroImage ? "mx-auto text-center" : ""}
            `,
                {
                  "text-3xl md:text-6xl":
                    variant === Enum_Componentsectionshero_Variant.Small,
                  "text-xl sm:text-4xl ":
                    variant === Enum_Componentsectionshero_Variant.Large,
                }
              )}
              dangerouslySetInnerHTML={{
                __html: titleHtml || "",
              }}
            />
            {subTitle && (
              <Paragraph
                className={`mt-12 text-white sm:text-2xl font-bold max-w-prose 
              
              `}
              >
                {subTitle}
              </Paragraph>
            )}

            {descriptionHtml && (
              <p
                className={`mt-12 text-white max-w-prose 
                
              `}
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
            )}
            {button && (
              <div className="mt-12 inline-block">
                <LinkButton
                  style={
                    button.buttonStyle ===
                    Enum_Componentnavigationlinkbutton_Buttonstyle.Filled
                      ? "default"
                      : "outlined"
                  }
                  fullWidth={false}
                  href={
                    button.externalUrl
                      ? button.externalUrl
                      : button?.page?.data?.attributes?.path
                  }
                  linkTarget={
                    button.target ===
                    Enum_Componentnavigationlinkbutton_Target.Self
                      ? "_self"
                      : "_blank"
                  }
                >
                  {button.label}
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
