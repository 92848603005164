"use client";
import Image from "next/image";
import {
  ComponentElementsResponsiveImage,
  Enum_Componentnavigationlinkbutton_Buttonstyle,
  Enum_Componentnavigationlinkbutton_Target,
  SliderFragment,
} from "@/graphql/generated/graphql";
import { getResponsiveImageClasses } from "@/lib/getResponsiveImageClasses";
import React, { Fragment } from "react";
import { ComponentRenderer } from "@digitale-kumpel/client-common/src/components/ComponentRenderer";
import { notEmpty } from "@digitale-kumpel/client-common/src/utils/notEmpty";
import { componentMap } from "@/config/componentMap";
import { ScrollSnapCarousel } from "./ScrollSnapCarousel";
import { Container } from "./Container";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton";

function Slider({ backgroundImage, slider, cta }: SliderFragment) {
  const responsiveImageClasses = getResponsiveImageClasses(
    (backgroundImage?.filter(Boolean) as ComponentElementsResponsiveImage[]) ||
      []
  );

  const hasBackgroundImage = backgroundImage && backgroundImage.length > 0;

  const slides = slider?.data?.attributes?.slides || [];
  const items = slides.filter(notEmpty).map((item) => ({
    renderItem(item) {
      return (
        <ComponentRenderer components={[item]} componentMap={componentMap} />
      );
    },
    item,
  }));

  return (
    <div className={`relative py-24`}>
      <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
        {backgroundImage?.map((image) => (
          <Fragment key={image?.id}>
            {image?.image?.data?.attributes?.url && (
              <Image
                src={image?.image.data?.attributes?.url || ""}
                alt={image?.image.data?.attributes?.alternativeText || ""}
                fill
                sizes="100vw"
                className={`${
                  responsiveImageClasses[image?.screenSize || ""]
                } w-full h-full object-center object-cover`}
                priority={true}
                loading="eager"
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className="max-w-[800px] mx-auto">
        <ScrollSnapCarousel
          className="mb-12"
          items={items || []}
          itemsPerSlide={1}
        />
      </div>
      {cta && (
        <div className="mx-auto flex items-center">
          <LinkButton
            className="relative mx-auto block"
            style={
              cta?.buttonStyle ===
              Enum_Componentnavigationlinkbutton_Buttonstyle.Outlined
                ? "outlined"
                : "default"
            }
            fullWidth={false}
            href={cta.externalUrl || cta.page?.data?.attributes?.path || "#"}
            linkTarget={
              cta?.target === Enum_Componentnavigationlinkbutton_Target.Blank
                ? "_blank"
                : "_self"
            }
          >
            {cta.label}
          </LinkButton>
        </div>
      )}
    </div>
  );
}

export { Slider };
