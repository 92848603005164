import { Button } from "@digitale-kumpel/client-common/src/components/actions/Button";
import React from "react";
import ArrowDownTrayIcon from "@heroicons/react/24/outline/ArrowDownTrayIcon";
import { ComponentElementsDownloadButton } from "@/graphql/generated/graphql";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton";

export interface IDownloadButton extends ComponentElementsDownloadButton {}

function DownloadButton({ file }: IDownloadButton) {
  return (
    <LinkButton
      href={file?.data?.attributes?.url}
      linkTarget={"_blank"}
      fullWidth={false}
      style="outlined"
      className="flex items-center"
      buttonClassName="text-gray-800 border-gray-800 hover:bg-gray-100"
    >
      <ArrowDownTrayIcon className="h-6 w-6 mr-1" />
      Herunterladen
    </LinkButton>
  );
}

export { DownloadButton };
