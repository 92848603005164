import classnames from "classnames";
import {
  Enum_Componentelementsheadline_Position,
  Enum_Componentelementsheadline_Variant,
} from "@/graphql/generated/graphql";
import { Headline } from "./Headline";

export function ContentBlock({
  textAlign = "left",
  html,
  headline = "",
  headlineVariant = Enum_Componentelementsheadline_Variant.H2,
  textColor = "text-gray-900",
  children,
  headlineAlignment,
}: {
  textAlign: "left" | "right";
  textColor?: string;
  html: string;
  headline?: string;
  headlineVariant?: Enum_Componentelementsheadline_Variant;
  children?: React.ReactNode;
  headlineAlignment?: Enum_Componentelementsheadline_Position;
}) {
  return (
    <div
      className={classnames(`max-w-prose py-4 xl:py-24 px-4 md:px-10 my-auto`, {
        "md:text-right": textAlign === "right",
      })}
    >
      <div
        className={classnames("mb-8", {
          "md:text-center":
            headlineAlignment ===
            Enum_Componentelementsheadline_Position.Center,
          "md:text-right":
            headlineAlignment === Enum_Componentelementsheadline_Position.Right,
        })}
      >
        <Headline color={textColor} variant={headlineVariant} text={headline} />
      </div>
      <div
        className={`prose ${textColor}`}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="mt-8">{children}</div>
    </div>
  );
}
