"use client";

import {
  ComponentSectionsVideo,
  Enum_Componentelementsheadline_Variant,
  Enum_Componentnavigationlinkbutton_Buttonstyle,
  Enum_Componentsectionsvideo_Mediaposition,
} from "@/graphql/generated/graphql";
import { Fragment, useEffect, useState } from "react";
import { PlayIcon } from "@heroicons/react/24/solid";
import { ContentBlock } from "@digitale-kumpel/client-common/src/components/dataDisplay/ContentBock/ContentBlock";
import { ContentContainer } from "@digitale-kumpel/client-common/src/components/layout/ContentContainer/ContentContainer";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton/LinkButton";
import { TextLink } from "@digitale-kumpel/client-common/src/components/navigation/TextLink/TextLink";
import { Dialog, Transition } from "@headlessui/react";
import Image from "next/image";
import YouTube from "react-youtube";

export function Video({
  youtubeId,
  html,
  videoMediaPosition,
  textAlign,
  textColumns,
  videoColumns,
  button,
  headline,
  video,
  vimeoId,
  consentBackgroundImage,
  showInModal,
}: ComponentSectionsVideo & {
  videoMediaPosition: Enum_Componentsectionsvideo_Mediaposition;
}): JSX.Element {
  const isMediaLeft =
    videoMediaPosition === Enum_Componentsectionsvideo_Mediaposition.Left;
  const buttonUrl =
    button?.page?.data?.attributes?.path || button?.externalUrl || "#";

  /**
   * Show the click area to open the video
   * It needs to be hidden if video is open
   * otherwise the click on the backdrop goes
   * through and then reopens the video when
   * click was above the image
   */
  const [showClickArea, setShowCliectArea] = useState(true);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  useEffect(() => {
    if (!isVideoOpen) {
      setTimeout(() => {
        setShowCliectArea(true);
      }, 10);
    }
    if (isVideoOpen) {
      setShowCliectArea(false);
    }
  }, [isVideoOpen]);

  const VideoPlayer = () => (
    <div className="aspect-h-9 aspect-w-16">
      {video?.data?.attributes?.url && (
        <video controls src={video?.data?.attributes?.url}>
          <track
            label={video?.data?.attributes?.alternativeText || ""}
            kind="captions"
            srcLang="de"
            src=""
          />
        </video>
      )}
      {youtubeId && (
        <YouTube
          iframeClassName="w-full"
          opts={{
            height: "100%",
            width: "100%",
            host: "https://www.youtube-nocookie.com",
          }}
          videoId={youtubeId}
        />
      )}
      {vimeoId && (
        <iframe
          className=""
          src={`https://player.vimeo.com/video/${vimeoId}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      )}
    </div>
  );

  const VideoContent = () => (
    <div className="flex flex-col h-full justify-center">
      <div className="relative">
        {((!showInModal && !isVideoOpen) || showInModal) &&
          consentBackgroundImage?.data?.attributes?.url && (
            <>
              <Image
                src={consentBackgroundImage?.data?.attributes?.url || ""}
                alt=""
                width={1280}
                height={720}
              />
              {showClickArea && (
                <div
                  className="flex items-center absolute top-0 left-0 right-0 bottom-0 bg-red"
                  onClick={(e) => {
                    if (!isVideoOpen) {
                      setIsVideoOpen(true);
                    }
                  }}
                >
                  <button className="mx-auto px-10 py-3 rounded-lg bg-slate-300 bg-opacity-90 hover:bg-opacity-100">
                    <PlayIcon className="w-24 h-24 mx-auto text-white" />
                  </button>
                </div>
              )}
            </>
          )}
      </div>
      {((!showInModal && isVideoOpen) ||
        !consentBackgroundImage?.data?.attributes?.url) && (
        <div className="w-full">
          <VideoPlayer />
        </div>
      )}
    </div>
  );

  const TextContent = () => (
    <ContentBlock
      headline={headline?.text || ""}
      headlineVariant={
        headline?.variant || Enum_Componentelementsheadline_Variant.H2
      }
      headlineAlignment={headline?.position}
      textAlign={textAlign}
      html={html || ""}
    >
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Text && (
        <TextLink>Mehr Lesen</TextLink>
      )}
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Outlined && (
        <LinkButton
          style="outlined"
          href={buttonUrl}
          fullWidth={false}
          linkTarget={button?.target || "_self"}
        >
          {button?.label}
        </LinkButton>
      )}
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Filled && (
        <LinkButton
          style="default"
          href={buttonUrl}
          fullWidth={false}
          linkTarget={button?.target || "_self"}
        >
          {button?.label}
        </LinkButton>
      )}
    </ContentBlock>
  );

  if (!html && !headline?.text) {
    return <VideoContent />;
  }

  return (
    <>
      <ContentContainer
        leftColumns={isMediaLeft ? videoColumns : textColumns}
        rightColumns={isMediaLeft ? textColumns : videoColumns}
        leftContent={isMediaLeft ? <VideoContent /> : <TextContent />}
        rightContent={isMediaLeft ? <TextContent /> : <VideoContent />}
      />
      <Transition show={isVideoOpen && Boolean(showInModal)} as={Fragment}>
        <Dialog
          onClose={() => {
            setIsVideoOpen(false);
          }}
          className="relative z-50 "
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <div
            className="fixed inset-0 flex w-screen items-center justify-center p-4 "
            onClick={() => {}}
          >
            <Transition.Child
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              as={Fragment}
            >
              <Dialog.Panel className="mx-auto max-w-6xl w-full bg-white">
                <VideoPlayer />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
