import React from "react";
import { ComponentElementsRichText } from "@/graphql/generated/graphql";
import cn from "classnames";

function RichText({
  html,
  align,
  __typename,
}: ComponentElementsRichText): JSX.Element {
  return (
    <div
      className={cn("componentText prose max-w-prose text-base", {
        "text-left": align === "left",
        "mx-auto text-center": align === "center",
        "text-right": align === "right",
      })}
      dangerouslySetInnerHTML={{ __html: html || "" }}
      data-component-name={__typename}
    />
  );
}

export { RichText };
