"use client";

import classNames from "classnames";
import dynamic from "next/dynamic";
import React, { useRef, useState } from "react";
import { getCurrentBreakpoint } from "@/lib/getCurrentBreakPoint";

const ScrollSnapNavigation = dynamic(
  () => {
    return import("./ScrollSnapNavigation").then(
      (mod) => mod.ScrollSnapNavigation
    );
  },
  { ssr: false }
);

export function ScrollSnapCarousel({
  items,
  className,
  itemsPerSlide = 4,
}: {
  items: { renderItem: (item: any, i: number) => JSX.Element; item: any }[];
  className?: string;
  itemsPerSlide: number;
}): JSX.Element {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  function getSlidesToScroll() {
    const currentBreakpoint = getCurrentBreakpoint();
    return (
      {
        xs: 1,
        sm: 2,
        md: itemsPerSlide / 2,
        lg: itemsPerSlide,
      }[currentBreakpoint] ?? itemsPerSlide
    );
  }
  const slidesToScroll = getSlidesToScroll();

  function handleSlide(to: "next" | "prev") {
    // initially the index is -1 so we can
    const currentSlide = currentSlideIndex === -1 ? 0 : currentSlideIndex;
    let newScrollSlide;
    if (to === "prev") {
      newScrollSlide = Math.max(0, currentSlide - slidesToScroll);
    }
    if (to === "next") {
      newScrollSlide = Math.min(
        items.length - 1,
        currentSlide + slidesToScroll
      );
    }

    const child = sliderRef.current?.children[newScrollSlide];
    setCurrentSlideIndex(newScrollSlide);
    // @ts-ignore
    if (child?.scrollIntoViewIfNeeded) {
      // @ts-ignore
      child.scrollIntoViewIfNeeded();
    } else if (child?.scrollIntoView) {
      child.scrollIntoView();
    }
  }

  return (
    <div className="relative">
      <div
        ref={sliderRef}
        id="product-slider"
        className={classNames(
          `flex snap-x snap-mandatory overflow-x-scroll scroll-smooth scrollable-div scrollable-div`,
          {
            "gap-x-8": !Boolean(className),
            [className as string]: Boolean(className),
          }
        )}
      >
        {items.map((item, i) => (
          <div
            key={`item-${i}`}
            className={classNames("w-full shrink-0 snap-start", {
              "w-full": itemsPerSlide === 1,
              "sm:w-1/2 lg:w-1/2": itemsPerSlide === 2,
              "sm:w-1/2 lg:w-1/3": itemsPerSlide === 3,
              "sm:w-1/2 lg:w-1/4": itemsPerSlide === 4,
              "sm:w-1/2 lg:w-1/5": itemsPerSlide === 5,
              "sm:w-1/2 lg:w-1/6": itemsPerSlide === 6,
            })}
          >
            <div className="px-4">{item.renderItem(item.item, i)}</div>
          </div>
        ))}
      </div>
      <ScrollSnapNavigation
        onSlide={handleSlide}
        slidesToScroll={slidesToScroll}
        items={items}
        canScrollRight={currentSlideIndex < items.length - 1}
        canScrollLeft={currentSlideIndex > 0}
      />
    </div>
  );
}
