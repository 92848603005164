import React, { Fragment } from "react";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton";
import { TextLink } from "@digitale-kumpel/client-common/src/components/navigation/TextLink";
import classnames from "classnames";
import Image from "next/image";
import {
  ComponentNavigationLinkButton,
  ComponentSectionsImageText,
  Enum_Componentelementsheadline_Variant,
  Enum_Componentnavigationlinkbutton_Buttonstyle,
  Enum_Componentsectionsimagetext_Mediaposition,
} from "@/graphql/generated/graphql";
import { notEmpty } from "@digitale-kumpel/client-common/src/utils/notEmpty";
import { ContentBlock } from "./ContentBlock";

function Buttons({ buttons }: { buttons: ComponentNavigationLinkButton[] }) {
  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2">
      {buttons.map((button) => {
        const buttonUrl =
          button?.page?.data?.attributes?.path || button?.externalUrl || "#";
        return (
          <Fragment key={button.id}>
            {button?.buttonStyle ===
              Enum_Componentnavigationlinkbutton_Buttonstyle.Text && (
              <TextLink>Mehr Lesen</TextLink>
            )}
            {button?.buttonStyle ===
              Enum_Componentnavigationlinkbutton_Buttonstyle.Outlined && (
              <LinkButton
                style="outlined"
                href={buttonUrl}
                fullWidth={false}
                linkTarget={button?.target || "_self"}
              >
                {button?.label}
              </LinkButton>
            )}
            {button?.buttonStyle ===
              Enum_Componentnavigationlinkbutton_Buttonstyle.Filled && (
              <LinkButton
                style="default"
                href={buttonUrl}
                fullWidth={false}
                linkTarget={button?.target || "_self"}
              >
                {button?.label}
              </LinkButton>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export function ImageText({
  hideImageOnMobile,
  imageColumns,
  mediaPosition,
  textAlign,
  textColumns,
  button,
  fixedHeight,
  headline,
  html,
  image,
  imagePriority,
  screenHeight,
  buttons,
}: ComponentSectionsImageText): JSX.Element {
  const buttonUrl =
    button?.page?.data?.attributes?.path || button?.externalUrl || "#";
  const renderContentBlock = () => (
    <ContentBlock
      headline={headline?.text || ""}
      headlineVariant={
        headline?.variant || Enum_Componentelementsheadline_Variant.H2
      }
      headlineAlignment={headline?.position}
      textAlign={textAlign}
      html={html || ""}
      textColor={``}
    >
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Text && (
        <TextLink>Mehr Lesen</TextLink>
      )}
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Outlined && (
        <LinkButton
          style="outlined"
          href={buttonUrl}
          fullWidth={false}
          linkTarget={button?.target || "_self"}
        >
          {button?.label}
        </LinkButton>
      )}
      {button?.buttonStyle ===
        Enum_Componentnavigationlinkbutton_Buttonstyle.Filled && (
        <LinkButton
          style="default"
          href={buttonUrl}
          fullWidth={false}
          linkTarget={button?.target || "_self"}
        >
          {button?.label}
        </LinkButton>
      )}
      {buttons && <Buttons buttons={buttons.filter(notEmpty)} />}
    </ContentBlock>
  );

  return (
    <div
      className={classnames("flex flex-col md:flex-row sm:items-center", {})}
    >
      {/* lorem picsum image */}
      <div
        className={classnames("md:justify-self-end", {
          "basis-1/2": textColumns === imageColumns,
          "basis-1/3": textColumns === 1 && imageColumns === 2,
          "basis-2/3": textColumns === 2 && imageColumns === 1,
          "basis-1/4": textColumns === 1 && imageColumns === 3,
          "basis-3/4": textColumns === 3 && imageColumns === 1,
          "basis-1/5": textColumns === 1 && imageColumns === 4,
          "basis-4/5": textColumns === 4 && imageColumns === 1,
          [`md:col-span-${textColumns}`]: Boolean(textColumns),
          [`min-h-screen`]: Boolean(screenHeight),
          [`hidden`]:
            mediaPosition ===
            Enum_Componentsectionsimagetext_Mediaposition.Left,
          [`hidden md:flex`]:
            mediaPosition ===
            Enum_Componentsectionsimagetext_Mediaposition.Right,
        })}
        style={{ height: `${fixedHeight ? fixedHeight + "px" : "auto"}` }}
      >
        {renderContentBlock()}
      </div>
      <div
        className={classnames({
          "basis-1/2": textColumns === imageColumns,
          "basis-1/3": textColumns === 2 && imageColumns === 1,
          "basis-2/3": textColumns === 1 && imageColumns === 2,
          "basis-1/4": textColumns === 3 && imageColumns === 1,
          "basis-3/4": textColumns === 1 && imageColumns === 3,
          "basis-1/5": textColumns === 4 && imageColumns === 1,
          "basis-4/5": textColumns === 1 && imageColumns === 4,
        })}
      >
        <Image
          src={image?.data?.attributes?.url || ""}
          className=""
          alt=""
          width={image?.data?.attributes?.width || 500}
          height={image?.data?.attributes?.height || 500}
          priority={Boolean(imagePriority)}
        />
      </div>
      <div
        className={classnames("", {
          "basis-1/2": textColumns === imageColumns,
          "basis-1/3": textColumns === 1 && imageColumns === 2,
          "basis-2/3": textColumns === 2 && imageColumns === 1,
          "basis-1/4": textColumns === 1 && imageColumns === 3,
          "basis-3/4": textColumns === 3 && imageColumns === 1,
          "basis-1/5": textColumns === 1 && imageColumns === 4,
          "basis-4/5": textColumns === 4 && imageColumns === 1,
          [`md:col-span-${textColumns}`]: Boolean(textColumns),
          [`min-h-screen`]: Boolean(screenHeight),
          [`flex`]:
            mediaPosition ===
            Enum_Componentsectionsimagetext_Mediaposition.Left,
          [`flex md:hidden`]:
            mediaPosition ===
            Enum_Componentsectionsimagetext_Mediaposition.Right,
        })}
        style={{ height: `${fixedHeight ? fixedHeight + "px" : "auto"}` }}
      >
        {renderContentBlock()}
      </div>
    </div>
  );
}
