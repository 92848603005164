import React from "react";
import { Headline } from "./Headline";
import { ComponentSectionsCallToAction } from "@/graphql/generated/graphql";
import { LinkButton } from "@digitale-kumpel/client-common/src/components/navigation/LinkButton/LinkButton";
import { Container } from "./Container";
import { RichText } from "@digitale-kumpel/client-common/src/components/typography";

export function CallToAction({
  button,
  descriptionHtml,
  title,
}: ComponentSectionsCallToAction): JSX.Element {
  return (
    <>
      <div className="py-12 bg-primary-100 my-8">
        <Container>
          <Headline variant={"h2"} className="text-center">
            {title}
          </Headline>
        </Container>
      </div>
      <Container className="pb-12 text-center flex flex-col items-center">
        <div className="mx-auto">
          <RichText id="1" html={descriptionHtml} />
        </div>
        <div className="">
          <LinkButton
            fullWidth={false}
            href={button?.externalUrl || "#"}
            linkTarget={button?.target || "_self"}
          >
            {button?.label}
          </LinkButton>
        </div>
      </Container>
    </>
  );
}
