import {
  Enum_Componentelementsheadline_Position,
  SectionHeadlineFragment,
} from "@/graphql/generated/graphql";
import React from "react";
import { Container } from "./Container";
import { Headline } from "./Headline";

function SectionHeadline({ headline, __typename }: SectionHeadlineFragment) {
  if (!headline) return <></>;
  const { text, position, variant } = headline;

  return (
    <div data-component-name={__typename} className="py-4 bg-primary-100 my-8">
      <Container>
        <Headline
          className={`
        ${
          position === Enum_Componentelementsheadline_Position.Center
            ? "text-center"
            : ""
        }
        ${
          position === Enum_Componentelementsheadline_Position.Right
            ? "text-right"
            : ""
        }
        `}
          variant={variant || "h2"}
        >
          {text}
        </Headline>
      </Container>
    </div>
  );
}

export { SectionHeadline };
