export const getCurrentBreakpoint = (): string => {
  const _document = typeof document === 'undefined' ? null : document;
  const breakpointUnknown: string = 'unknown';
  const breakpointXS: string | null =
    _document?.getElementById('breakpoint-xs')?.offsetParent === null
      ? null
      : 'xs';
  const breakpointSM: string | null =
    _document?.getElementById('breakpoint-sm')?.offsetParent === null
      ? null
      : 'sm';
  const breakpointMD: string | null =
    _document?.getElementById('breakpoint-md')?.offsetParent === null
      ? null
      : 'md';
  const breakpointLG: string | null =
    _document?.getElementById('breakpoint-lg')?.offsetParent === null
      ? null
      : 'lg';
  const breakpointXL: string | null =
    _document?.getElementById('breakpoint-xl')?.offsetParent === null
      ? null
      : 'xl';
  const breakpoint2XL: string | null =
    _document?.getElementById('breakpoint-2xl')?.offsetParent === null
      ? null
      : '2xl';
  const breakpoint =
    breakpointXS ??
    breakpointSM ??
    breakpointMD ??
    breakpointLG ??
    breakpointXL ??
    breakpoint2XL ??
    breakpointUnknown;
  return breakpoint;
};
