import { LinkList } from "@digitale-kumpel/client-common/src/components/navigation/LinkList/LinkList";
import { Newsletter } from "@digitale-kumpel/client-common/src/components/sections/Newsletter/Newsletter";
import { PagePreviewList } from "@digitale-kumpel/client-common/src/components/sections/PagePreviewList/PagePreviewList";
import { Container } from "@digitale-kumpel/client-common/src/components/layout/Container/Container";
import { Navbar } from "@digitale-kumpel/client-common/src/components/navigation/Navbar/Navbar";
import { DownloadButton } from "@/components/DownloadButton";
import { CodeEmbed } from "@/components/CodeEmbed";
import { Video } from "@/components/Video";
import { ImageText } from "@/components/ImageText";
import { Hero } from "@/components/Hero";
import { Spacer } from "@/components/Spacer";
import { SectionHeadline } from "@/components/SectionHeadline";
import { CallToAction } from "@/components/CallToAction";
import { Slider } from "@/components/Slider";
import { RichText } from "@/components/RichText";

export const componentMap = {
  // elements
  ComponentElementsRichText: (props) => (
    <Container>
      <RichText {...props} />
    </Container>
  ),
  ComponentElementsDownloadButton: DownloadButton,
  // navigation
  ComponentNavigationLinkList: LinkList,
  ComponentNavigationNavbar: Navbar,
  // sections
  ComponentSectionsCodeEmbed: (props) => (
    <div className="my-12">
      <CodeEmbed {...props} />
    </div>
  ),
  ComponentSectionsNlSub: (props) => (
    <div className="bg-gray-100 py-12 sm:py-24">
      <Container>
        <Newsletter {...props} />
      </Container>
    </div>
  ),
  ComponentSectionsPagePreviewList: PagePreviewList,
  ComponentSectionsVideo: (props) => (
    <div className="bg-slate-50">
      <Container className="py-4">
        <Video {...props} />
      </Container>
    </div>
  ),
  ComponentSectionsImageText: ImageText,
  ComponentSectionsHero: Hero,
  ComponentElementsSpacer: Spacer,

  ComponentSectionsSectionHeadline: SectionHeadline,
  ComponentSectionsCallToAction: CallToAction,
  ComponentSectionsSlider: Slider,
};
